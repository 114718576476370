<template>
  <div>
    <h1>
      <template v-if="tokenStatus == 'invalid'">
        Password Reset Unsuccessful
      </template>
      <template v-else-if="formSuccess">
        Password Reset Complete
      </template>
      <template v-else>
        Enter a New Password
      </template>
    </h1>

    <b-alert
      v-if="tokenStatus == 'invalid'"
      variant="danger"
      show
    >
      The password reset link was invalid, possibly because it has already
      been used. Please
      <router-link
        :to="{name: 'password_reset'}"
        class="alert-link"
      >
        <!-- eslint-disable-next-line vue/multiline-html-element-content-newline -->
        request a new password reset</router-link>.
    </b-alert>
    <b-alert
      v-else-if="formSuccess"
      variant="success"
      show
    >
      Your password has been set. You may go ahead and
      <router-link
        :to="{name: 'login'}"
        class="alert-link"
      >
        <!-- eslint-disable-next-line vue/multiline-html-element-content-newline -->
        log in</router-link> now.
    </b-alert>
    <template v-else>
      <p
        v-if="tokenStatus == 'checking'"
        class="text-center"
      >
        Loading...
      </p>
      <template v-else-if="tokenStatus == 'valid'">
        <p class="text-center">
          Please enter your new password twice so we can verify you typed it in
          correctly.
        </p>

        <b-alert
          v-if="formErrors.non_field_errors !== undefined"
          variant="danger"
          show
        >
          {{ formErrors.non_field_errors[0] }}
        </b-alert>

        <b-form @submit.prevent="submitForm">
          <b-form-group
            label="New password"
            label-for="new_password1"
            :label-sr-only="true"
          >
            <b-form-input
              id="new_password1"
              v-model="new_password1"
              type="password"
              required
              placeholder="New password confirmation"
              :state="formErrors.new_password1 === undefined ? null : false"
              :aria-describedby="'new_password1-help' + (formErrors.new_password1 === undefined ? '' : ' new_password1-feedback')"
            />
            <b-form-invalid-feedback
              v-if="formErrors.new_password1 !== undefined"
              id="new_password1-feedback"
            >
              {{ formErrors.new_password1[0] }}
            </b-form-invalid-feedback>
            <b-form-text id="new_password1-help">
              <ul>
                <li>Your password can't be too similar to your other personal information.</li>
                <li>Your password must contain at least 8 characters.</li>
                <li>Your password can't be a commonly used password.</li>
                <li>Your password can't be entirely numeric.</li>
              </ul>
            </b-form-text>
          </b-form-group>

          <b-form-group
            label="New password confirmation"
            label-for="new_password2"
            :label-sr-only="true"
          >
            <b-form-input
              id="new_password2"
              v-model="new_password2"
              type="password"
              required
              placeholder="New password confirmation"
              :state="formErrors.new_password2 === undefined ? null : false"
              :aria-describedby="formErrors.new_password2 === undefined ? false : 'new_password2-feedback'"
            />
            <b-form-invalid-feedback
              v-if="formErrors.new_password2 !== undefined"
              id="new_password2-feedback"
            >
              {{ formErrors.new_password2[0] }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            :disabled="formSubmitting"
          >
            <template v-if="formSubmitting">
              Setting Password
              <spinner />
            </template>
            <template v-else>
              Set Password
            </template>
          </b-button>
        </b-form>
      </template>
      <b-alert
        v-else-if="tokenStatus == 'error'"
        variant="danger"
        show
      >
        <p>
          Unable to communicate with the server. Please check your connection
          and try again.
        </p>

        <b-button
          variant="light"
          @click="checkPasswordResetLink"
        >
          Try Again
        </b-button>
      </b-alert>
    </template>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'PasswordResetConfirmation',
  components: {
    Spinner,
  },
  props: {
    uidb64: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tokenStatus: 'checking',
      formSubmitting: false,
      formErrors: {},
      formSuccess: false,
      new_password1: '',
      new_password2: '',
    };
  },
  metaInfo: {
    title: 'Password Reset',
  },
  mounted() {
    this.checkPasswordResetLink();
  },
  methods: {
    async checkPasswordResetLink() {
      this.tokenStatus = 'checking';

      const postData = {
        uidb64: this.uidb64,
        token: this.token,
      };

      let response;

      try {
        response = await fetch(
          `${process.env.VUE_APP_API_URL}users/check_password_reset_link/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      if (response && response.status === 200) {
        const responseJson = await response.json();
        this.tokenStatus = responseJson.valid ? 'valid' : 'invalid';
      } else {
        this.tokenStatus = 'error';
      }
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const postData = {
        uidb64: this.uidb64,
        token: this.token,
        new_password1: this.new_password1,
        new_password2: this.new_password2,
      };

      let response;

      try {
        response = await fetch(
          `${process.env.VUE_APP_API_URL}users/password_reset_submission/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      this.formSubmitting = false;

      if (response && [200, 400].includes(response.status)) {
        if (response.status === 200) {
          this.formSuccess = true;
        } else {
          const responseJson = await response.json();
          this.formErrors = responseJson;
        }
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
};
</script>
